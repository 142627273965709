import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import Helmet from 'react-helmet';
import {usePageContext} from '@components/Page';
import Meta from '@components/Meta';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';
import LegalPage from '@components/LegalPage';

interface PurchaseOrderTermsAndConditionsPageProps {
  data: {
    cms: {
      page: {
        searchDescription: string;
        seoTitle: string;
        body: Block[];
      };
    };
  };
}

const PurchaseOrderTermsAndConditionsPage = ({
  data,
}: PurchaseOrderTermsAndConditionsPageProps) => {
  usePageContext({shouldInvertMenuColors: true});
  const {seoTitle, searchDescription, body} = data.cms.page;
  const {draftailCopy} = getDataFromBlockTypes(body);
  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <LegalPage {...draftailCopy} />
    </Fragment>
  );
};

export default PurchaseOrderTermsAndConditionsPage;

export const query = graphql`
  fragment PurchaseOrdersTCFragment on CMS_LegalPage {
    seoTitle
    searchDescription
    body {
      ...headerBodyFragment
    }
  }
  query PurchaseOrdersTC {
    cms {
      page(url: "/home/purchase-order-terms-conditions/") {
        title
        ...PurchaseOrdersTCFragment
      }
    }
  }
`;
